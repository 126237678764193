import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import { useState } from "react";
import Lottie from "react-lottie-player";
import Sparkles from "../../../assets/lottie/sparkles.json";
import { ReactComponent as Locker } from "../../../assets/images/svg/locker-icon.svg";
import { RegistrationFormData } from "../../../interfaces/login";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye-icon.svg";
import { ReactComponent as Copy } from "../../../assets/images/svg/copy-icon.svg";
import { ReactComponent as Info3 } from "../../../assets/images/svg/info3-icon.svg";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData } from "../../../apis/apiMethods";
import { toast } from "react-toastify";
import { errorHandler } from "../../../helpers/errorHandler";
import { Loader } from "../../../component/Loader/Loader.component";

const GenerateAPIModal = ({
  setCloseModal,
  showModal,
  type,
  publicTest,
  publicLive,
  secretTest,
  secretLive,
  env,
}: {
  setCloseModal: (newState: boolean) => void;
  showModal: boolean;
  type: string;
  publicTest: string;
  publicLive: string;
  secretTest: string;
  secretLive: string;
  env: string;
}) => {
  const [state, setState] = useState("generate");
  const [myPassword, setMyPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [myNewValue, setMyNewValue] = useState("");
  const [inputType, setInputType] = useState("password");

  const style = {
    height: 200,
    width: 200,
  };

  let inputValue;

  switch (true) {
    case env === "Test" && type === "Public":
      inputValue = publicTest;
      break;
    case env === "Test" && type === "Secret":
      inputValue = secretTest;
      break;
    case env === "Live" && type === "Public":
      inputValue = publicLive;
      break;
    case env === "Live" && type === "Secret":
      inputValue = secretLive;
      break;
    default:
      // Default value if none of the conditions match
      inputValue = null;
  }

  const schemaPassword = yup.object().shape({
    password: yup.string().required("Input must not be empty"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schemaPassword),
  });

  const onSubmit = async ({ password }: { password: string }) => {
    setLoading(true);
    setMyPassword(password);
    const encodedPassword = window.btoa(password);
    const reqBody = {
      env,
      type,
    };
    try {
      const res: any = await postData(
        `
        ${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_TOKENS}`,
        reqBody
        // { "x-aella-user-password": encodedPassword }
      );

      toast.success(res.message);
      // refetch();
      setState("success");

      switch (true) {
        case env === "Test" && type === "Public":
          setMyNewValue(res?.data?.prefix + res?.data?.value || "");
          break;
        case env === "Test" && type === "Secret":
          setMyNewValue(res?.data?.prefix + res?.data?.value || "");
          break;
        case env === "Live" && type === "Public":
          setMyNewValue(res?.data?.prefix + res?.data?.value || "");
          break;
        case env === "Live" && type === "Secret":
          setMyNewValue(res?.data?.prefix + res?.data?.value || "");
          break;
        default:
          // Default value if none of the conditions match
          setMyNewValue("");
      }
      setLoading(false);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  const renderContent = () => {
    switch (state) {
      case "generate":
        return (
          <form onSubmit={handleSubmit(onSubmit)} className="p-[40px] relative">
            <p className="text-[24px] text-[#0B1E4B]">Please Note</p>

            <p className="mt-[16px] text-[#5B5B5B] font-thin mb-[40px]">
              Kindly note that by regenerating your API key, the old key will be
              revoked and no longer be function. To continue, input the password
              tied to this account or cancel to go back.
            </p>

            <div>
              <label className="text-[14px] text-[#232323] font-thin">
                Enter password tied to this account
              </label>
              <div className="relative">
                <input
                  {...register("password")}
                  name="password"
                  type={inputType}
                  placeholder="Enter Password"
                  className="h-[48px] w-full outline-none  mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[40px] border border-[#2323232E] rounded-[8px]"
                />
                <Locker className="absolute left-[15px] top-[55%] translate-y-[-50%]" />
                {inputType === "password" ? (
                  <Eye
                    onClick={() => setInputType("text")}
                    className="absolute right-[16px] top-[50%]"
                  />
                ) : (
                  <Eye
                    onClick={() => setInputType("password")}
                    className="absolute right-[16px] top-[50%]"
                  />
                )}
              </div>
              <p className="text-[12px] text-[#E50A13]">
                {errors?.password?.message}
              </p>
            </div>

            <div className="flex justify-center mt-[40px]">
              <button className="px-[42px] py-[16px] rounded-[4px] flex items-center justify-center text-white font-thin bg-[#2054D2]  ">
                Re-generate API Key {loading ? <Loader /> : null}
              </button>
            </div>
          </form>
        );
      case "success":
        return (
          <div className="p-[40px] pt-[88px] relative">
            <div className="absolute left-[50%] translate-x-[-50%] top-[40px]">
              <Lottie animationData={Sparkles} style={style} play speed={1} />
            </div>

            <p className="text-[26px] text-[#2054D2] text-center mb-[80px]">
              Successful
            </p>

            <p className="text-2xl text-[#0B1E4B] text-center">
              New API Key Generated
            </p>

            <p className="mt-4 text-[#5B5B5B] font-light text-center mb-6">
              Your new API key can be found below.
            </p>

            <div className="mb-6">
              <div className="relative">
                <input
                  type="text"
                  readOnly
                  defaultValue={myNewValue}
                  className="h-[40px] w-full outline-none bg-[#EDEFF5] mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[16px] border border-[#E6E9F3] truncate pr-[40px] rounded-[8px]"
                />
                <Copy
                  onClick={() => {
                    navigator.clipboard.writeText(myNewValue);
                    toast.success("Copied");
                  }}
                  className="absolute right-[16px] cursor-pointer top-[50%]"
                />
              </div>
            </div>

            <div className="bg-[#F8C1C6] rounded-lg border border-[#F5A2AA] p-4 flex gap-2 mb-10">
              <Info3 />

              <div className="">
                <p className="text-[#76232B] font-medium text-sm mb-2">
                  Please Note
                </p>
                <p className="text-sm text-[#76232B] font-light">
                  Please save your newly generated API key. For security
                  reasons, it will not be visible again after you close this
                  window.
                </p>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => {
                  setCloseModal(!showModal);
                }}
                className="px-[42px] py-[16px] rounded-[4px] text-white font-thin bg-[#2054D2] inline-block "
              >
                Go Back
              </button>
            </div>
          </div>
        );

      default:
        return;
    }
  };

  return (
    <>
      <div className="absolute justify-center items-center flex inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between w-[501px]">
            <div></div>
            <div
              onClick={() => {
                setCloseModal(!showModal);
              }}
            >
              {state === "generate" && (
                <img
                  src={CloseIcon}
                  alt="close modal"
                  className="cursor-pointer"
                />
              )}
            </div>
          </div>

          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white flex flex-col justify-center rounded-[8px]">
              <div className="">
                <div className="">{renderContent()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"></div>
    </>
  );
};

export default GenerateAPIModal;
