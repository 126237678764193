import GenerateAPIModal from "../Modal/GenerateAPIModal";
import { useState } from "react";
import { ROUTES } from "../../../helpers/routes";
import Tab from "../../../component/Tab/Tab.component";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useMerchantDetails } from "../../../hooks/useMerchantDetails";
import { fetchWebhookURL, useAllTokens } from "../../../hooks/useAllTokens";
import { WebhookInput } from "../Components/WebhookInput";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { toast } from "react-toastify";
import { ReactComponent as Copy } from "../../../assets/images/svg/copy-icon.svg";
import { useQueries, useQueryClient } from "react-query";
import { errorHandler } from "../../../helpers/errorHandler";
import { Loader } from "../../../component/Loader/Loader.component";

const WebHooks = () => {
  const [openModal, setOpenModal] = useState(false);
  const [keyType, setKeyType] = useState("");
  const [keyENV, setKeyENV] = useState("");
  const [liveValue, setLiveValue] = useState("");
  const [testValue, setTestValue] = useState("");
  const [testWebhookURL, setTestWebhookURL] = useState("");
  const [liveWebhookURL, setLiveWebhookURL] = useState("");
  const [testloading, setTestLoading] = useState(false);
  const [liveLoading, setLiveLoading] = useState(false);

  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const { data: merchantDetails, isLoading: isLoadingDetails } =
    useMerchantDetails({ userId });

  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useAllTokens();

  const [{}, {}] = useQueries([
    {
      queryKey: ["webhookURL", "TEST"],
      queryFn: () => fetchWebhookURL("TEST"),
      onSuccess: (data) => {
        setTestWebhookURL(data?.data?.url);
      },
      onError: (err: any) => {
        toast.error("Unable to retrieve test webhook");
      },
    },
    {
      queryKey: ["webhookURL", "LIVE"],
      queryFn: () => fetchWebhookURL("LIVE"),
      onSuccess: (data) => {
        setLiveWebhookURL(data?.data?.url);
      },
      onError: (err: any) => {
        toast.error("Unable to retrieve live webhook");
      },
    },
  ]);

  const handleWebhookURL = async (
    url: string,
    environment: "TEST" | "LIVE"
  ) => {
    const reqBody = {
      url,
      environment,
    };
    environment === "TEST" ? setTestLoading(true) : setLiveLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.WEBHOOK}`,
        reqBody
      );
      queryClient.invalidateQueries("webhookData");
      toast.success(res.message);
      setTestLoading(false);
      setLiveLoading(false);
    } catch (error) {
      toast.error(errorHandler(error));
      setTestLoading(false);
      setLiveLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <div className="flex ">
              <Tab text="Profile" path={ROUTES.SETTINGS_PROFILE} />
              <Tab text="Security" path={ROUTES.SETTINGS_SECURITY} />
              <Tab text="Notifications" path={ROUTES.SETTINGS_NOTIFICATIONS} />
              <Tab text="Manage Team" path={ROUTES.SETTINGS_MANAGE_TEAM} />
              {/* 
              <Tab
                text="Withdrawal Bank Accounts"
                path={ROUTES.SETTINGS_WITHDRAWAL}
              /> */}
              <Tab text="API Keys & Webhooks" path={ROUTES.SETTINGS_API} />
            </div>
          </div>
        </div>
        <div className="py-[32px] px-[10px] lg:p-10">
          <div className="bg-white border border-[#EDEFF5] p-[10px] lg:p-10 pt-[30px] min-h-[70vh]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5]">
                <p className="text-[24px] text-[#0B1E4B]">
                  API Keys & Webhooks{" "}
                </p>
              </div>
            </div>
            <>
              <div className="flex justify-between">
                <div className="p-[24px] w-[48%] border border-[#F1F1F1] rounded-[8px] mt-[40px]">
                  <p className="text-[18px] border-b border-[#F1F1F1] font-[400] text-[#011C34] pb-[10px] mb-[24px]">
                    Sandbox Tokens
                  </p>
                  <div className=" w-full flex flex-col gap-[24px]">
                    <WebhookInput
                      keyTitle="Regenerate Public Key"
                      clickAction={() => {
                        setKeyENV("Test");
                        setKeyType("Public");
                        setOpenModal(true);
                        refetch();
                      }}
                    />

                    <WebhookInput
                      keyTitle="Regenerate Secret Key"
                      clickAction={() => {
                        setKeyENV("Test");
                        setKeyType("Secret");
                        setOpenModal(true);
                        refetch();
                      }}
                    />

                    <div className="text-sm text-[#232323] mb-2 font-light">
                      <p className="">WebHooks URL</p>
                      <div className="relative">
                        <input
                          type="text"
                          defaultValue={testWebhookURL}
                          value={testValue || testWebhookURL}
                          placeholder="https://test-webhook-url.com"
                          onChange={(e) => setTestValue(e.target.value)}
                          className="h-[40px] w-full outline-none bg-[#EDEFF5] mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[16px] border border-[#E6E9F3] truncate pr-[40px] rounded-[8px]"
                        />

                        {testWebhookURL && (
                          <Copy
                            onClick={() => {
                              navigator.clipboard.writeText(testWebhookURL);
                              toast.success("Copied");
                            }}
                            className="absolute right-[16px] cursor-pointer top-[50%]"
                          />
                        )}
                      </div>

                      <button
                        onClick={() => handleWebhookURL(testValue, "TEST")}
                        className={` text-[14px] font-[400] px-5 py-4 rounded-md mt-6  flex items-center justify-center gap-3 transition-all delay-75 ease-in-out duration-150 ${
                          testValue && testValue !== testWebhookURL
                            ? "text-white bg-[#2054D2] cursor-pointer"
                            : "bg-[#D2D2D2] text-[#7B7B7B] cursor-not-allowed"
                        }`}
                        disabled={!testValue || testValue === testWebhookURL}
                      >
                        Save changes {testloading ? <Loader /> : null}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="p-[24px] w-[48%] border border-[#F1F1F1] rounded-[8px] mt-[40px]">
                  <p className="text-[18px] border-b border-[#F1F1F1] font-[400] text-[#011C34] pb-[10px] mb-[24px]">
                    Live Tokens
                  </p>
                  <div className=" w-full flex flex-col gap-[24px]">
                    <WebhookInput
                      keyTitle="Regenerate Public Key"
                      clickAction={() => {
                        setKeyENV("Live");
                        setKeyType("Public");
                        setOpenModal(true);
                        refetch();
                      }}
                    />

                    <WebhookInput
                      keyTitle="Regenerate Secret Key"
                      clickAction={() => {
                        setKeyENV("Live");
                        setKeyType("Secret");
                        setOpenModal(true);
                        refetch();
                      }}
                    />

                    <div className="text-sm text-[#232323] mb-2 font-light">
                      <p className="">WebHooks URL</p>
                      <div className="relative">
                        <input
                          type="text"
                          value={liveValue || liveWebhookURL}
                          placeholder="https://live-webhook-url.com"
                          onChange={(e) => setLiveValue(e.target.value)}
                          className="h-[40px] w-full outline-none bg-[#EDEFF5] mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[16px] border border-[#E6E9F3] truncate pr-[40px] rounded-[8px]"
                        />

                        {liveWebhookURL && (
                          <Copy
                            onClick={() => {
                              navigator.clipboard.writeText(liveWebhookURL);
                              toast.success("Copied");
                            }}
                            className="absolute right-[16px] cursor-pointer top-[50%]"
                          />
                        )}
                      </div>

                      <button
                        onClick={() => handleWebhookURL(liveValue, "LIVE")}
                        className={` text-[14px] font-[400] px-5 py-4 rounded-md mt-6  flex items-center justify-center gap-3 transition-all delay-75 ease-in-out duration-150 ${
                          liveValue && liveValue !== liveWebhookURL
                            ? "text-white bg-[#2054D2] cursor-pointer"
                            : "bg-[#D2D2D2] text-[#7B7B7B] cursor-not-allowed"
                        }`}
                        disabled={!liveValue || liveValue === liveWebhookURL}
                      >
                        Save changes {liveLoading ? <Loader /> : null}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>

      {openModal && (
        <GenerateAPIModal
          publicTest={data?.data[3]?.check}
          publicLive={data?.data[1]?.check}
          secretLive={data?.data[0]?.check}
          secretTest={data?.data[2]?.check}
          type={keyType}
          env={keyENV}
          showModal={openModal}
          setCloseModal={setOpenModal}
        />
      )}
    </>
  );
};

export default WebHooks;
