import BlueButton from "../../../component/Button/BlueButton";

export const WebhookInput = ({
  clickAction,
  keyTitle,
}: {
  clickAction: any;
  keyTitle: string;
}) => {
  return (
    <div>
      <div className="">
        <BlueButton title={keyTitle} onClick={clickAction} />
      </div>
    </div>
  );
};
