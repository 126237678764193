import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllTokens = async () => {
  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_TOKENS}`
  );
  return res;
};

export const useAllTokens = () => {
  return useQuery(["getAllTokens"], getAllTokens, {
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

export const fetchWebhookURL = async (environment: "TEST" | "LIVE") => {
  const data: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.WEBHOOK}/${environment}`
  );
  return data;
};
